var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('overlay',{attrs:{"show":_vm.busy}},[_c('v-data-table',{staticClass:"data-table border-g-e8",attrs:{"items":_vm.items,"headers":_vm.headers,"disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-start audit-va-top"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v(" "+_vm._s(item.created_at.format('HH:mm:ss'))+" UTC ")]),_c('div',[_vm._v(" "+_vm._s(item.created_at.format('YYYY-MM-DD'))+" ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"audit-type-button",attrs:{"color":_vm.getColor(item),"fab":"","small":"","depressed":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(item)))])],1)],1)],1)],1),_c('td',{staticClass:"text-start"},[_c('v-row',[_c('v-col',[_c('code',[_c('router-link',{attrs:{"to":_vm.getLink(_vm.entity, item.object_id)}},[_vm._v(" "+_vm._s(_vm.entityFQN)+"#"+_vm._s(item.object_id)+" ")])],1),_vm._v(" has been "),_c('b',[_vm._v(_vm._s(_vm.getAction(item)))]),(item.target)?_c('span',[_vm._v(" "+_vm._s(item.direction)+" "),_c('code',[_c('router-link',{attrs:{"to":_vm.getLink(
                                                    item.target.class,
                                                    item.target.id
                                                )}},[_vm._v(" "+_vm._s(item.target.class)+"#"+_vm._s(item.target.id)+" ")])],1)]):_vm._e(),_vm._v(" by "),_c('b',[_vm._v(_vm._s(item.blame_user || 'unknown user'))]),(item.ip)?_c('span',[_vm._v(", IP: "+_vm._s(item.ip))]):_vm._e()]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-chip',{attrs:{"color":_vm.getColor(item),"small":"","label":""}},[_vm._v(" "+_vm._s(item.type)+" ")])],1),(
                                    item.type == 'insert' ||
                                    item.type == 'update'
                                )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t('audit.property'))+" ")]),_c('th',{attrs:{"width":"35%"}},[_vm._v(" "+_vm._s(_vm.$t('audit.old_value'))+" ")]),_c('th',{attrs:{"width":"35%"}},[_vm._v(" "+_vm._s(_vm.$t('audit.new_value'))+" ")])])]),_c('tbody',_vm._l((item.diffs),function(values,key){return _c('tr',{key:key},[_c('td',[_c('code',[_vm._v(_vm._s(key))])]),_c('td',{staticClass:"text-break"},[(
                                                        values.old === null
                                                    )?_c('code',[_vm._v(" null ")]):_c('diff-value',{staticClass:"error--text",attrs:{"value":values.old}})],1),_c('td',{staticClass:"text-break"},[(
                                                        values.new === null
                                                    )?_c('code',[_vm._v(" null ")]):_c('diff-value',{class:values.old === null
                                                            ? 'info--text'
                                                            : 'success--text',attrs:{"value":values.new}})],1)])}),0)])],1):_vm._e()],1)],1)])]}}])})],1),_c('portal',{attrs:{"to":"footer-right"}},[_c('div',{staticClass:"d-flex justify-end my-n1"},[(_vm.pageCount > 1)?_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"7","color":"primary"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }