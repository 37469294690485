<template>
    <div>
        <v-card v-if="showWarning" outlined class="red">
            <v-card-text class="white--text">
                {{ warningMessage }}
            </v-card-text>
        </v-card>

        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :show-progress="showProgress"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template v-slot:col1>
                        <h3 class="headline pt-3 pb-6 black--text">
                            {{ $t('labels.canteen_order_settings_title') }}
                        </h3>

                        <text-input
                            v-model="form.name"
                            v-validate="'required|max:255|space'"
                            name="name"
                            label="labels.name"
                            :error-messages="errors.collect('name')"
                            :data-vv-as="$t('labels.name')"
                        ></text-input>

                        <div class="py-4">
                            <entity-select-input
                                v-model="form.addresses"
                                v-validate="'required'"
                                label="labels.addresses"
                                type="checkbox"
                                :data-vv-as="$t('labels.addresses')"
                                :entity="selectApiClientType.COMPANY_ADDRESS"
                                :query-params="{ company: form.company }"
                                multi-select
                                name="addresses"
                                :error-messages="errors.collect('addresses')"
                            ></entity-select-input>
                        </div>

                        <entity-select-input
                            v-model="form.companyTeams"
                            v-validate="''"
                            :entity="selectApiClientType.COMPANY_TEAMS"
                            :query-params="{
                                companyId,
                            }"
                            multi-select
                            label="labels.teams"
                            name="teams"
                            :error-messages="errors.collect('teams')"
                            :data-vv-as="$t('labels.teams')"
                        ></entity-select-input>

                        <recur-dates-common-form-part
                            v-model="form"
                        ></recur-dates-common-form-part>
                    </template>
                    <template v-slot:col2>
                        <h3 class="headline pt-3 pb-6 black--text">
                            {{ $t('labels.cloudcanteen_budget_title') }}
                        </h3>

                        <checkbox-input
                            v-model="hasBudget"
                            class="mt-3 mb-5"
                            name="hasBudget"
                            label="labels.budget_available"
                        ></checkbox-input>

                        <div v-show="hasBudget">
                            <currency-input
                                v-model="form.budgetLimit"
                                v-validate="'min_value:0|decimal:2'"
                                name="budgetLimit"
                                label="labels.budget"
                                :error-messages="errors.collect('budgetLimit')"
                                :data-vv-as="$t('labels.budget')"
                            ></currency-input>

                            <div
                                class="d-flex mx-n3 mt-4 justify-space-between"
                            >
                                <div class="pa-3">
                                    <h3>
                                        {{ $t('labels.cc_budget_overrides') }}
                                    </h3>
                                </div>
                                <div class="pa-3">
                                    <add-budget-override
                                        :base-limit="form.budgetLimit"
                                        @added="addBudgetOverride"
                                    ></add-budget-override>
                                </div>
                            </div>

                            <v-row
                                v-for="(override,
                                index) in form.budgetOverrides"
                                :key="override['@id']"
                                class="mb-n4"
                            >
                                <v-col cols="6" class="pb-0 pt-4">
                                    <entity-select-input
                                        v-model="override.user"
                                        v-validate="'required'"
                                        :name="`overrideUser[${override['@id']}]`"
                                        label="labels.employee"
                                        :entity="selectApiClientType.USER"
                                        :query-params="{
                                            company: companyApiClient.getItemIri(
                                                companyId
                                            ),
                                        }"
                                        :items-filter="
                                            overrideEmployeesFilter(override)
                                        "
                                        :needs-update="overrideSelectUpdate"
                                        autocomplete
                                        :data-vv-as="$t('labels.employee')"
                                        :error-messages="
                                            errors.collect(
                                                `overrideUser[${override['@id']}]`
                                            )
                                        "
                                        @input="updateOverrideSelect()"
                                    ></entity-select-input>
                                </v-col>

                                <v-col cols="4" class="py-0">
                                    <currency-input
                                        v-model="override.budgetLimit"
                                        v-validate="'required|decimal:2'"
                                        :name="`overrideBudgetLimit[${override['@id']}]`"
                                        label="labels.budget"
                                        type="number"
                                        :data-vv-as="$t('labels.budget')"
                                        :error-messages="
                                            errors.collect(
                                                `overrideBudgetLimit[${override['@id']}]`
                                            )
                                        "
                                    ></currency-input>
                                </v-col>

                                <v-col cols="2" class="pb-0 pt-3">
                                    <v-btn
                                        icon
                                        color="error"
                                        @click="removeBudgetOverride(index)"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <div class="pb-4"></div>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.expense_code_settings_title') }}
                        </h3>

                        <expense-codes
                            v-model="form"
                            additional-type="CloudcanteenExpenseCode"
                        ></expense-codes>
                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.canteen_notifications_title') }}
                        </h3>

                        <div class="pb-6">
                            <checkbox-input
                                v-model="form.sendNotificationWhenOpen"
                                name="sendNotificationWhenOpen"
                                label="labels.send_notification_when_open"
                            ></checkbox-input>
                        </div>

                        <text-input
                            v-model="form.sendNotificationBeforeCutoff"
                            v-validate="'required|numeric'"
                            type="number"
                            name="sendNotificationBeforeCutoff"
                            label="labels.send_notification_before_cutoff"
                            :suffix="$t('labels.minutes')"
                            :error-messages="
                                errors.collect('sendNotificationBeforeCutoff')
                            "
                            :data-vv-as="
                                $t('labels.send_notification_before_cutoff')
                            "
                        ></text-input>
                        <div class="g-b2--text mt-n3">
                            {{ notificationTime || '-' }}
                        </div>
                    </template>
                </form-two-col-row>
                <form-one-col-row>
                    <canteen-restaurant-form-part
                        v-model="form"
                        type="CloudcanteenRestaurant"
                        :restaurant-entity="selectApiClientType.RESTAURANT"
                        :restaurant-query-param="{
                            ccCompanyPartner: form.company,
                        }"
                        :company="company"
                    ></canteen-restaurant-form-part>
                </form-one-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import configType from '@/store/type/configType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import FormBase from '@/components/mixins/FormBase'
import CanteenEditDisableMixin from '@/components/mixins/CanteenEditDisableMixin'
import FormPanel from '@/components/form/FormPanel.vue'
import FormTwoColRow from '@/components/form/FormTwoColRow.vue'
import FormOneColRow from '@/components/form/FormOneColRow.vue'
import CanteenRestaurantFormPart from '@/components/pages/canteen/CanteenRestaurantFormPart.vue'
import RecurDatesCommonFormPart from '@/components/pages/canteen/RecurDatesCommonFormPart.vue'
import TextInput from '@/components/form/Inputs/TextInput.vue'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput.vue'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput.vue'
import AddBudgetOverride from './AddBudgetOverride.vue'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput.vue'
import ExpenseCodes from '@/components/form/Parts/ExpenseCodes.vue'
import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        FormOneColRow,
        CanteenRestaurantFormPart,
        RecurDatesCommonFormPart,
        TextInput,
        CurrencyInput,
        EntitySelectInput,
        CheckboxInput,
        AddBudgetOverride,
        ExpenseCodes,
    },
    mixins: [FormBase, CanteenEditDisableMixin],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        let baseline = this.$date().set('minute', 0).set('second', 0)
        if (baseline.set('hour', 12).isBefore(this.$date())) {
            baseline = baseline.add(1, 'day')
        }
        const cutoff = baseline.set('hour', 10)
        const delivery = baseline.set('hour', 12)
        const open = baseline.subtract(1, 'day').set('hour', 9)
        return {
            api: ApiClientType.COMPANY_CLOUDCANTEENS,
            mapper: MapperType.COMPANY_CLOUDCANTEENS_FORM,
            form: {
                name: null,
                openTime: open.format(DATE_TIME_FORMAT),
                cutoffTime: cutoff.format(DATE_TIME_FORMAT),
                earliestCutoffTime: cutoff.format(DATE_TIME_FORMAT),
                deliveryTime: delivery.format(DATE_TIME_FORMAT),
                budgetLimit: '0',
                relatedRestaurants: [],
                addresses: [],
                pickupOffset: 15,
                canGroupOrder: false,
                isRecurring: false,
                recurringWeeks: 1,
                budgetOverrides: [],
                sendNotificationBeforeCutoff: null,
                sendNotificationWhenOpen: false,
                companyTeams: [],
                expenseCodeOnlyFromList: false,
                expenseCodeList: [],
                expenseCodeRequired: null,
                expenseCodeTitle: this.$t('defaults.expense_code_title'),
                expenseCodeRegexValidation: null,
                expenseCodeRegexValidationError: null,
                expenseCodePlaceholder: null,
                additionalExpenseCodes: [],
            },
            entity: 'labels.company_cloudcanteens',
            editRoute: routeType.COMPANY_CLOUDCANTEEN_EDIT,
            listRoute: routeType.COMPANY_CLOUDCANTEEN_LIST,
            companyApiClient: RestApiCollection.get(ApiClientType.COMPANIES),
            overrideSelectUpdate: false,
            company: null,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
            currencyLocale: configType.getters.CURRENCY_LOCALE,
        }),
        title() {
            return this.form.identifier
                ? `${this.form.name} (${this.form.identifier})`
                : this.form.name
        },
        notificationTime() {
            return this.form.cutoffTime
                ? this.$date(this.form.cutoffTime)
                      .subtract(
                          this.form.sendNotificationBeforeCutoff,
                          'minutes'
                      )
                      .format('YYYY-MM-DD HH:mm')
                : ''
        },
        hasBudget: {
            get() {
                return this.form.budgetLimit !== null
            },
            set(value) {
                if (value) {
                    this.form.budgetLimit = '0'
                } else {
                    this.form.budgetLimit = null
                }
            },
        },
    },
    created() {
        this.form.company = this.companyApiClient.getItemIri(this.companyId)
        this.loadCompanyData()
    },
    methods: {
        editRedirectParams(data) {
            return {
                cloudcanteenId: data.id,
                companyId: this.companyId,
            }
        },
        listRedirectParams() {
            return { companyId: this.companyId }
        },
        updateOverrideSelect() {
            this.overrideSelectUpdate = !this.overrideSelectUpdate
        },
        removeBudgetOverride(index) {
            this.form.budgetOverrides.splice(index, 1)
            this.updateOverrideSelect()
        },
        addBudgetOverride(val) {
            if (
                this.form.budgetOverrides &&
                Array.isArray(this.form.budgetOverrides)
            ) {
                this.$set(this.form, 'budgetOverrides', [
                    ...this.form.budgetOverrides,
                    val,
                ])
            } else {
                this.$set(this.form, 'budgetOverrides', [val])
            }
        },
        overrideEmployeesFilter(item) {
            return (values) =>
                values.filter(
                    (value) =>
                        value.key === item.user ||
                        !this.form.budgetOverrides ||
                        this.form.budgetOverrides.length === 0 ||
                        !this.form.budgetOverrides
                            .map((ovr) => ovr.user)
                            .includes(value.key)
                )
        },
        loadCompanyData() {
            this.companyApiClient.get(this.companyId).then((response) => {
                this.company = response
                if (this.form.sendNotificationBeforeCutoff === null)
                    this.$set(
                        this.form,
                        'sendNotificationBeforeCutoff',
                        response.defaultCanteenNotificationTime
                    )
            })
        },
    },
}
</script>
