<template>
    <div>
        <expense-code
            v-model="form"
            title-prop="expenseCodeTitle"
            code-list-prop="expenseCodeList"
            required-prop="expenseCodeRequired"
            only-from-list-prop="expenseCodeOnlyFromList"
        ></expense-code>
        <div v-for="(code, index) in form.additionalExpenseCodes" :key="index">
            <expense-code
                v-model="form.additionalExpenseCodes[index]"
                is-additional
            >
                <v-btn color="error" icon @click="removeExpenseCode(index)">
                    <v-icon>delete</v-icon>
                </v-btn>
            </expense-code>
        </div>
        <div>
            <v-btn color="info" class="white--text" @click="addExpenseCode">
                {{ $t('actions.add') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import ExpenseCode from './ExpenseCode.vue'

const defaultAdditionalExpensecode = {
    title: '',
    availableExpenseCodes: [],
    isRequired: true,
    onlyFromList: true,
    expenseCodeRegexValidation: null,
    expenseCodeRegexValidationError: null,
    expenseCodePlaceholder: null,
}

export default {
    components: {
        ExpenseCode,
    },
    mixins: [FormPartMixin],
    props: {
        additionalType: {
            type: String,
            default: 'CompanyAllowanceExpenseCode',
        },
    },
    methods: {
        addExpenseCode() {
            this.form.additionalExpenseCodes.push({
                ...cloneDeep(defaultAdditionalExpensecode),
                '@type': this.additionalType,
            })
        },
        removeExpenseCode(index) {
            this.form.additionalExpenseCodes.splice(index, 1)
        },
    },
}
</script>
