<template>
    <portal v-if="$slots.default || text" to="header" :order="1">
        <h1 class="page-title-heading">
            <slot>{{ text }}</slot>
        </h1>
    </portal>
</template>

<script>
export default {
    props: {
        title: String,
        translated: String,
    },
    data: () => ({
        observer: null,
    }),
    computed: {
        text() {
            return this.translated
                ? this.translated
                : this.title
                ? this.$t(this.title)
                : null
        },
    },
    watch: {
        text: {
            immediate: false,
            handler() {
                this.setDocumentTitle()
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.setDocumentTitle()
            const heading = document.querySelector('.page-title-heading')
            const observer = new MutationObserver(this.setDocumentTitle)
            observer.observe(heading, {
                childList: true,
                subtree: true,
                characterData: true,
            })
            this.observer = observer
        })
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect()
        }
    },
    methods: {
        setDocumentTitle() {
            const text =
                this.text ||
                this.$slots.default?.[0]?.text ||
                document.querySelector('.page-title-heading')?.textContent
            document.title = `${text} | ${this.$t('global.app_title')}`
        },
    },
}
</script>
