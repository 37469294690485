<template>
    <div>
        <page-title>
            <div class="d-flex align-center">
                <template v-if="id">
                    <router-link
                        :to="{ name: routeName, params: { entity } }"
                        class="black--text"
                        >{{ entity }}</router-link
                    >
                    <span class="ml-4">#{{ id }}</span>
                </template>
                <template v-else>
                    {{ entity }}
                    <form
                        class="ml-4 d-flex align-center"
                        @submit.prevent="gotoId"
                    >
                        <v-text-field
                            v-model="identifier"
                            label="#ID"
                            hide-details
                        ></v-text-field>
                    </form>
                </template>
            </div>
        </page-title>
        <audit-history :id="id" :entity="entity"></audit-history>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PageTitle from '@/components/layout/components/pageTitle.vue'
import AuditHistory from '@/components/pages/audit/History.vue'

export default {
    components: {
        PageTitle,
        AuditHistory,
    },
    props: {
        entity: {
            type: String,
            default: null,
        },
        id: {
            type: [Number, String],
            default: null,
        },
    },
    data: () => ({
        routeName: routeType.AUDIT_LOG_HISTORY,
        identifier: null,
    }),
    methods: {
        gotoId() {
            if (this.identifier) {
                this.$router.push({
                    name: this.routeName,
                    params: {
                        entity: this.entity,
                        id: this.identifier,
                    },
                })
                this.identifier = null
            }
        },
    },
}
</script>
