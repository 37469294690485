<template>
    <div>
        <overlay :show="busy">
            <v-data-table
                :items="items"
                :headers="headers"
                class="data-table border-g-e8"
                disable-filtering
                disable-pagination
                hide-default-footer
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td class="text-start audit-va-top">
                            <v-row>
                                <v-col cols="auto">
                                    <h3>
                                        {{ item.created_at.format('HH:mm:ss') }}
                                        UTC
                                    </h3>
                                    <div>
                                        {{
                                            item.created_at.format('YYYY-MM-DD')
                                        }}
                                    </div>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn
                                        class="audit-type-button"
                                        :color="getColor(item)"
                                        fab
                                        small
                                        depressed
                                    >
                                        <v-icon>{{ getIcon(item) }}</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </td>
                        <td class="text-start">
                            <v-row>
                                <v-col>
                                    <code>
                                        <router-link
                                            :to="
                                                getLink(entity, item.object_id)
                                            "
                                        >
                                            {{ entityFQN }}#{{ item.object_id }}
                                        </router-link>
                                    </code>
                                    has been <b>{{ getAction(item) }}</b>
                                    <span v-if="item.target">
                                        {{ item.direction }}
                                        <code>
                                            <router-link
                                                :to="
                                                    getLink(
                                                        item.target.class,
                                                        item.target.id
                                                    )
                                                "
                                            >
                                                {{ item.target.class }}#{{
                                                    item.target.id
                                                }}
                                            </router-link>
                                        </code>
                                    </span>
                                    by
                                    <b>{{
                                        item.blame_user || 'unknown user'
                                    }}</b>
                                    <span v-if="item.ip"
                                        >, IP: {{ item.ip }}</span
                                    >
                                </v-col>
                                <v-col class="text-right" cols="2">
                                    <v-chip :color="getColor(item)" small label>
                                        {{ item.type }}
                                    </v-chip>
                                </v-col>
                                <v-col
                                    v-if="
                                        item.type == 'insert' ||
                                        item.type == 'update'
                                    "
                                    cols="12"
                                >
                                    <v-simple-table dense>
                                        <thead>
                                            <tr>
                                                <th width="30%">
                                                    {{ $t('audit.property') }}
                                                </th>
                                                <th width="35%">
                                                    {{ $t('audit.old_value') }}
                                                </th>
                                                <th width="35%">
                                                    {{ $t('audit.new_value') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(values,
                                                key) in item.diffs"
                                                :key="key"
                                            >
                                                <td>
                                                    <code>{{ key }}</code>
                                                </td>
                                                <td class="text-break">
                                                    <code
                                                        v-if="
                                                            values.old === null
                                                        "
                                                    >
                                                        null
                                                    </code>
                                                    <diff-value
                                                        v-else
                                                        class="error--text"
                                                        :value="values.old"
                                                    ></diff-value>
                                                </td>
                                                <td class="text-break">
                                                    <code
                                                        v-if="
                                                            values.new === null
                                                        "
                                                    >
                                                        null
                                                    </code>
                                                    <diff-value
                                                        v-else
                                                        :class="
                                                            values.old === null
                                                                ? 'info--text'
                                                                : 'success--text'
                                                        "
                                                        :value="values.new"
                                                    ></diff-value>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </overlay>
        <portal to="footer-right">
            <div class="d-flex justify-end my-n1">
                <div v-if="pageCount > 1">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="7"
                        color="primary"
                    ></v-pagination>
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import AuditLogApiClient from '@/api/RestApi/AuditLogApiClient'
import {
    getAction,
    transformHistoryItem,
} from '@/services/Audit/historyTransformers'
import { nsParam, paramNs } from '@/services/namespaceParam'
import Overlay from '@/components/overlay/Overlay.vue'
import DiffValue from './DiffValue.vue'

export default {
    components: {
        Overlay,
        DiffValue,
    },
    props: {
        entity: {
            type: String,
            default: null,
        },
        id: {
            type: [Number, String],
            default: null,
        },
    },
    data: () => ({
        items: [],
        busy: true,
        page: 1,
        pageCount: 1,
    }),
    computed: {
        headers() {
            return [
                {
                    value: 'time',
                    text: '',
                    class: 'audit-history-time-header audit-history-header',
                    sortable: false,
                },
                {
                    value: 'details',
                    text: '',
                    class: 'audit-history-header',
                    sortable: false,
                },
            ]
        },
        entityFQN() {
            return paramNs(this.entity)
        },
    },
    watch: {
        $route: {
            handler(to) {
                if (!to.query?.page) {
                    this.$router.replace({
                        ...to,
                        query: {
                            ...to.query,
                            page: 1,
                        },
                    })
                    return
                }
                this.page = parseInt(to.query.page || 1)
                this.updateItems()
            },
            immediate: true,
        },
        page(page, oldPage) {
            if (oldPage != page && this.$route.query?.page != page) {
                this.$router.push({
                    ...this.$route,
                    query: {
                        page,
                    },
                })
            }
        },
    },
    methods: {
        getAction(item) {
            return getAction(item)
        },
        getIcon(item) {
            switch (item.type) {
                case 'insert':
                    return 'add'
                case 'update':
                    return 'update'
                case 'remove':
                    return 'remove'
                case 'associate':
                    return 'add_link'
                case 'dissociate':
                    return 'link_off'
                default:
                    return 'question_mark'
            }
        },
        getColor(item) {
            switch (item.type) {
                case 'insert':
                    return 'success'
                case 'update':
                    return 'info'
                case 'remove':
                    return 'error'
                case 'associate':
                case 'dissociate':
                    return 'yellow'
                default:
                    return 'secondary'
            }
        },
        getLink(entity, id) {
            entity = nsParam(entity)
            return {
                name: routeType.AUDIT_LOG_HISTORY,
                params: {
                    entity,
                    id,
                },
            }
        },
        updateItems() {
            this.busy = true
            AuditLogApiClient.history(this.entity, this.id, { page: this.page })
                .then((response) => {
                    this.items = response.items
                    this.items.forEach((item) => {
                        item.diffs = JSON.parse(item.diffs)
                        item.created_at = this.$date.utc(item.created_at)
                        transformHistoryItem(item)
                    })
                    this.pageCount = response.pages
                })
                .finally(() => {
                    this.busy = false
                })
        },
    },
}
</script>

<style>
.audit-type-button {
    pointer-events: none;
}
.audit-history-header {
    height: 5px !important;
}
.audit-history-time-header {
    width: 180px;
}
.audit-va-top {
    vertical-align: top;
}
</style>
