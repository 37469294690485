<template>
    <form-panel>
        <div v-if="showInputs" class="d-flex align-center">
            <div class="pr-2 flex-grow-1">
                <text-input
                    v-model="form[titleProp]"
                    label="labels.title"
                ></text-input>
            </div>
            <div class="pl-2">
                <slot></slot>
            </div>
        </div>
        <form-one-col-row v-if="showInputs">
            <tag-input
                v-model="form[codeListProp]"
                label="labels.expense_code_list"
            ></tag-input>
            <p class="font-italic">
                {{ $t('labels.combobox_hint') }}
            </p>
        </form-one-col-row>
        <form-two-col-row>
            <template slot="col1">
                <select-input
                    v-if="!isAdditional"
                    v-model="form[requiredProp]"
                    name="expenseCodeRequired"
                    :items="expenseCodeRequired"
                    label="labels.expense_code_settings"
                ></select-input>
                <checkbox-input
                    v-else
                    v-model="form[requiredProp]"
                    label="labels.expense_code_required"
                    name="expenseCodeRequired"
                ></checkbox-input>
            </template>
            <template v-if="showInputs" slot="col2">
                <checkbox-input
                    v-model="form[onlyFromListProp]"
                    label="texts.employee_can_only_input_expense_code_from_list"
                    name="timeSettingsDontApplyToNonCompanyAddresses"
                ></checkbox-input>
            </template>
        </form-two-col-row>
        <form-panel>
            <form-two-col-row>
                <template #col1>
                    <text-input
                        v-model="form[regexValidatonProp]"
                        label="labels.expense_code.validation_regex"
                    ></text-input>
                </template>
                <template #col2>
                    <text-input
                        v-show="form[regexValidatonProp]"
                        v-model="form[regexValidationErrorProp]"
                        label="labels.expense_code.validation_regex_error"
                    ></text-input>
                </template>
            </form-two-col-row>
            <form-one-col-row>
                <text-input
                    v-model="form[regexCodePlaceholderProp]"
                    label="labels.expense_code.placeholder"
                ></text-input>
            </form-one-col-row>
        </form-panel>
    </form-panel>
</template>

<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import ExpenseCodeRequiredValuesMixin from '@/components/mixins/ExpenseCodeRequiredValues'
import FormPanel from '../FormPanel.vue'
import FormOneColRow from '../FormOneColRow.vue'
import FormTwoColRow from '../FormTwoColRow.vue'
import TextInput from '../Inputs/TextInput.vue'
import TagInput from '../Inputs/TagInput.vue'
import SelectInput from '../Inputs/SelectInput.vue'
import CheckboxInput from '../Inputs/CheckboxInput.vue'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        TagInput,
        SelectInput,
        CheckboxInput,
    },
    mixins: [FormPartMixin, ExpenseCodeRequiredValuesMixin],
    props: {
        titleProp: {
            type: String,
            default: 'title',
        },
        codeListProp: {
            type: String,
            default: 'availableExpenseCodes',
        },
        requiredProp: {
            type: String,
            default: 'isRequired',
        },
        onlyFromListProp: {
            type: String,
            default: 'onlyFromList',
        },
        regexValidatonProp: {
            type: String,
            default: 'expenseCodeRegexValidation',
        },
        regexValidationErrorProp: {
            type: String,
            default: 'expenseCodeRegexValidationError',
        },
        regexCodePlaceholderProp: {
            type: String,
            default: 'expenseCodePlaceholder',
        },
        isAdditional: Boolean,
    },
    computed: {
        showInputs() {
            return this.form[this.requiredProp] !== null
        },
    },
}
</script>
