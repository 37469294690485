export default {
    computed: {
        expenseCodeRequired() {
            return [
                {
                    title: this.$t('labels.not_required'),
                    value: null,
                },
                {
                    title: this.$t('labels.optional'),
                    value: 1,
                },
                {
                    title: this.$t('labels.required'),
                    value: 2,
                },
            ]
        },
    },
}
